import '../../../styles/market.css';
import React, { Component,useEffect,useState } from 'react';
import { Modal,Button} from "react-bootstrap";
import { Gear } from 'react-bootstrap-icons';
import {TransactionButton} from '../../../functions/Trx'; 
import { ethers } from 'ethers';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';

import assetIcon from '../../../img/icons/Avatar.png';
import honeyIcon from '../../../img/icons/HoneyIcon.png';

import { useAppContext } from '../../../context/AppContext'; // Import the useAppContext hook

const Market = () => {
    // Access context values
    const {
        USDCBalance,
        USDC_Address,
        MarketRouter_Address,
        location,
        web3_nm,
        outputNumber,
        address,
        MarketFactory_nm,
        USDDecimals,
        sleep,
        updateUserData,
        updateISSPrice,
        updateAssetBalanceWithAddress,
        updatePriceWithAddress,
        updatePortfolioValue,
        loadUSDBalance,
        slippage,
        trxTime,
        MarketRouter_ABI,
        MarketPair_ABI,
        ERC20_ABI,
        stableCoinName,
        assetDetails,
        GovernanceTokenBalance,
        GovernanceToken_Address,
        saveSlippagePreference,
        saveTrxTimePreference
        
    } = useAppContext();
    

    // State variables
    const [assets, setAssets] = useState([]);
    const [filteredAssets, setFilteredAssets] = useState([]);
    const [selectedAsset, setSelectedAsset] = useState("Select Asset");
    const [selectedAssetAddress, setSelectedAssetAddress] = useState('');
    const [selectedAssetBalance, setSelectedAssetBalance] = useState(0);
    const [chooseAssetModalOpen, setChooseAssetModalOpen] = useState(false);
    const [selectedAssetPrice, setSelectedAssetPrice] = useState(0);
    const [slectedAssetVolume, setSlectedAssetVolume] = useState(0);
    const [selectedUSDCVolume, setSelectedUSDCVolume] = useState(0);
    const [priceDataVisible, setPriceDataVisible] = useState(false);
    const [approvalButtonVisible, setApprovalButtonVisible] = useState(false);
    const [sellPartVisible, setSellPartVisible] = useState(false);
    const [buyPartVisible, setBuyPartVisible] = useState(true);
    const [style1, setStyle1] = useState("selectButtonSelected");
    const [style2, setStyle2] = useState("selectButtonUnselected");
    const [priceImpactBuy, setPriceImpactBuy] = useState(0);
    const [sellButtonVisible, setSellButtonVisible] = useState(false);
    const [settingsModalOpen, setSettingsModalOpen] = useState(false);
    const [buyButtonVisible, setBuyButtonVisible] = useState(false);
    const [warningButtonVisible, setWarningButtonVisible] = useState(false);
    const [warningButtonMessage, setWarningButtonMessage] = useState("warning");
    const [USDApprovalButtonVisible, setUSDApprovalButtonVisible] = useState(false);
    
    const [assetBalances, setAssetBalances] = useState();
    const [assetAddresses, setAssetAddresses] = useState();
    const [wrongSlippageInputMessage, setWrongSlippageInputMessage] = useState();
    const [highSlippageInputMessage, setHighSlippageInputMessage] = useState();
    const [wrongTrxTimeInputMessage, setWrongTrxTimeInputMessage] = useState();
    const [allowanceToken, setAllowanceToken] = useState();
    const [allowanceUSDC, setAllowanceUSDC] = useState();

    const [token0, setToken0] = useState();
    const [token1, setToken1] = useState();
    const [reserves0, setReserves0] = useState();
    const [reserves1, setReserves1] = useState();
    const [kFactor, setKFactor] = useState();
    const [AssetAmountIn, setAssetAmountIn] = useState();
    const [TokenPayoutAmount, setTokenPayoutAmount] = useState();
    const [tokenPrice, setTokenPrice] = useState();
    const [actualPrice, setActualPrice] = useState();
    const [amountIn, setAmountIn] = useState();
    const [amountOutMin, setAmountOutMin] = useState();
    const [path, setPath] = useState([]);
    const [deadline, setDeadline] = useState();
    const [USDCPayoutAmount, setUSDCPayoutAmount] = useState();
    const [USDCPayoutAmountMin, setUSDCPayoutAmountMin] = useState();
    const [priceImpact, setPriceImpact] = useState();
    const [liquidityProviderFee, setLiquidityProviderFee] = useState();
    const [USDCAmountIn, setUSDCAmountIn] = useState();
    const [TokenPayoutAmountMin, setTokenPayoutAmountMin] = useState();
    
    
    
    
    
    useEffect(() => {
        const initialize = async () => {
            console.log("DEBUG")
            console.log(USDC_Address)
            console.log(MarketRouter_Address)
            //check if the Stable coin Approval button needs to be shown
            let approvalGiven = await checkApproval(USDC_Address, MarketRouter_Address)
            if (approvalGiven === true) {
                setUSDApprovalButtonVisible(false)
            }
            const locationUnSplit = window.location.pathname;
            if (locationUnSplit) {
                const locationSplit = locationUnSplit.split("/");
                const buyOrSellVisible = locationSplit[2];
                const pairAddress = locationSplit[3];
                if (buyOrSellVisible === 'sell') {
                    await showSell();
                }
                initiatiatePage(pairAddress, buyOrSellVisible);
            }
        };
        initialize();
    }, []); // Empty dependency array means this runs once on component mount

    useEffect(() => {
        console.log(USDC_Address)
    }, [USDC_Address]);

  

    const initiatiatePage = async(pairAddress,buyOrSellVisible) =>{
        console.log(pairAddress)
        try{
            let MarketPair = new web3_nm.eth.Contract(MarketPair_ABI,pairAddress)
            let token0 = await MarketPair.methods.token0().call();
            let token1 = await MarketPair.methods.token1().call();
            let TokenContract0 = new web3_nm.eth.Contract(ERC20_ABI,token0)
            let tokenSymbol0 = await TokenContract0.methods.symbol().call()
            let TokenContract1 = new web3_nm.eth.Contract(ERC20_ABI,token1)
            let tokenSymbol1 = await TokenContract1.methods.symbol().call()
            let selectedAsset
            let assetAddress
            if (tokenSymbol0 === stableCoinName){
                selectedAsset = tokenSymbol1
                assetAddress = token1
            }
            else{
                selectedAsset = tokenSymbol0
                assetAddress = token0
            }
            console.log(selectedAsset)
            selectAssetInitial(selectedAsset, assetAddress, pairAddress)
        }
        catch(err){
            console.log(err.message)
            return
        }
    }

    const roundDown = (n,d) => {
        n = Math.floor(n*(10**d))
    
        n = n/(10**d)
        return n
    }

    const openSettingsModal = () => setSettingsModalOpen(true)
    const closeSettingsModal = () => setSettingsModalOpen(false)
    const checkSlippageInput = () => {
        console.log("Checking slippage input")
        try{
            let slippage = document.getElementById('slippage').value * 100
            console.log(slippage)
            if (isNaN(slippage)) {
                setWrongSlippageInputMessage(true)       
                return false
            }
            if (slippage < 0 || slippage > 5000) {
                setWrongSlippageInputMessage(true)
                setHighSlippageInputMessage(false)
                return false

            }
            if (slippage > 500 && slippage < 5000) {
                setWrongSlippageInputMessage(false)
                setHighSlippageInputMessage(true)        
                return true
            }
            setWrongSlippageInputMessage(false)
            setHighSlippageInputMessage(false)
            return (true)
            }
            catch{
                return (false)
            }
    }

    const saveSettingsSlippage=async()=>{
        let check = checkSlippageInput()
        if (check) {
            let slippage = document.getElementById('slippage').value * 100
            await saveSlippagePreference(slippage)
        }
    }

    const checkTrxTimeInput = () => {
        console.log("Checking trx time input")
        try{
            let trxTime = document.getElementById('trxTime').value
            console.log(trxTime)
            if (isNaN(trxTime) || trxTime <0 || trxTime > 600 ) {
                setWrongTrxTimeInputMessage(true)      
                return false
            }
            setWrongTrxTimeInputMessage(false)  
            return (true)
            }
            catch{
                return (false)
            }
    }

    

    const saveSettingsTrxTime=async()=>{
        let check = checkTrxTimeInput()
        console.log(check)
        if (check) {
            let trxTime = document.getElementById('trxTime').value
            await saveTrxTimePreference(trxTime)
        }
    }

    const openChooseAssetModal=()=>{
        let _assets = [];
        let _assetBalances = {};
        let _assetAddresses = {};
        _assets.push(["TWIN",GovernanceTokenBalance,"TWIN Protocol Token"]);
        _assetBalances["TWIN"]= GovernanceTokenBalance;
        _assetAddresses["TWIN"] = GovernanceToken_Address;

        for (let key in assetDetails) {
            console.log(key)
            console.log(assetDetails[key])
            if (typeof(assetDetails[key]['priceLong']) !== 'undefined'){
                _assets.push([key,assetDetails[key]['tokenBalance1'],assetDetails[key]['name']])
                _assetBalances[key] = assetDetails[key]['tokenBalance1']
                _assetAddresses[key] = assetDetails[key]['Token1']
            }
            if (typeof(assetDetails[key]['priceShort']) !== 'undefined'){
                console.log(assetDetails[key]['priceShort'])
                _assets.push(["i"+key,assetDetails[key]['tokenBalance2'],"short".concat(assetDetails[key]['name'])])
                _assetBalances["i"+key] = assetDetails[key]['tokenBalance2']
                _assetAddresses["i"+key] = assetDetails[key]['Token2']
            }
        }
        
        setAssets(_assets)
        setFilteredAssets(_assets)
        setAssetBalances(_assetBalances)
        setAssetAddresses(_assetAddresses)
        setChooseAssetModalOpen(true)
    };
    
    const closeChooseAssetModal = () => setChooseAssetModalOpen(false);

    const openChooseAssetModalSell=()=>{
        let _assets = [];
        let _assetBalances = {};
        let _assetAddresses = {};
        if (GovernanceTokenBalance > 0.001) {
            _assets.push(["TWIN",GovernanceTokenBalance,"TWIN Protocol Token"]);
            _assetBalances["TWIN"]= GovernanceTokenBalance;
            _assetAddresses["TWIN"] = GovernanceToken_Address;
        }
        else {
            console.log(GovernanceTokenBalance)
        }
        
        for (let key in assetDetails) {
            console.log(key)
            console.log(assetDetails[key])
            if (assetDetails[key]['tokenBalance1']>0.00001){
                _assets.push([key,assetDetails[key]['tokenBalance1'],assetDetails[key]['name']])
                _assetBalances[key] = assetDetails[key]['tokenBalance1']
            }
            if (assetDetails[key]['tokenBalance2']>0.00001){
                _assets.push(["i"+key,assetDetails[key]['tokenBalance2'],"short".concat(assetDetails[key]['name'])])
                _assetBalances["i"+key] = assetDetails[key]['tokenBalance2']
            }
            _assetAddresses[key] = assetDetails[key]['Token1']
            _assetAddresses["i"+key] = assetDetails[key]['Token2']
        }
        
        setAssets(_assets)
        setFilteredAssets(_assets)
        setAssetBalances(_assetBalances)
        setAssetAddresses(_assetAddresses)
        setChooseAssetModalOpen(true)
    };
    
    
    const filterAssets = () =>{
        let _filteredAssets =[];
        let searchTerm = document.getElementById('search').value.toLowerCase()
        for (let i = 0; i < assets.length; ++i) {
            if (assets[i][2].toLowerCase().includes(searchTerm) || assets[i][0].toLowerCase().includes(searchTerm)){
                _filteredAssets.push(assets[i])
            }
        }
        setFilteredAssets(_filteredAssets)
    }
    const listAssets = () => {
        let assetOptions = filteredAssets.map((element,index) =>
                <div key={index} className="assetSelectList" role="button" onClick={()=>selectAsset(element[0])}>
                    <div className="row">
                        <div className="col-3"><b>{element[0]}</b></div>
                        <div className="col text-right"><b>{element[2]}</b></div>
                    </div>
                    <div className="row">
                        <div className="col">Balance: {outputNumber(element[1],4)}</div>
                    </div>
                </div>
        );
        return(assetOptions)
    }
    
    const checkAllowances = async(_tokenAddress) =>{
        let tokenContract = new web3_nm.eth.Contract(ERC20_ABI,_tokenAddress)
        let _allowanceToken = await tokenContract.methods.allowance(address, MarketRouter_Address).call()
        setAllowanceToken(parseInt(_allowanceToken))
        let USDCContract = new web3_nm.eth.Contract(ERC20_ABI,USDC_Address)
        let _allowanceUSDC = await USDCContract.methods.allowance(address, MarketRouter_Address).call()
        setAllowanceUSDC(parseInt(_allowanceUSDC))
        //console.log(allowanceToken)
        //console.log(allowanceUSDC)
        //console.log(USDCAmountIn)
    }
    
    const selectAsset = async(asset) =>{
        console.log(asset)
        checkAllowances(assetAddresses[asset])

        setSelectedAsset(asset)
        setSelectedAssetBalance(assetBalances[asset])
        setSelectedAssetAddress(assetAddresses[asset])
        console.log(assetAddresses[asset])
        closeChooseAssetModal();
        
        let pair = await MarketFactory_nm.methods.getPair(assetAddresses[asset],USDC_Address).call()
        let MarketPair = new web3_nm.eth.Contract(MarketPair_ABI,pair)
        let balanceWEI = await MarketPair.methods.balanceOf(address).call()
        var balance = parseFloat(web3_nm.utils.fromWei(balanceWEI.toString(), 'ether'))
        let totalSupplyWEI = await MarketPair.methods.totalSupply().call();
        let reserves = await MarketPair.methods.getReserves().call();
        let token0 = await MarketPair.methods.token0().call();
        let token1 = await MarketPair.methods.token1().call();
        let kFactor = reserves[0] * reserves[1];
                
        setKFactor(kFactor)
        setReserves0(reserves[0])
        setReserves1(reserves[1])
        setToken0(token0)
        setToken1(token1)

        let _tokenPrice
        if (token0 === USDC_Address) {
            _tokenPrice = parseInt(reserves[0])/parseInt(reserves[1])
        }
        else{
            _tokenPrice = parseInt(reserves[1])/parseInt(reserves[0])
        }
        setTokenPrice(_tokenPrice)
        console.log(_tokenPrice)
        console.log(balance)
        

        //check if the Approval button needs to be shown
        let approvalGiven = await checkApproval(assetAddresses[asset], MarketRouter_Address)
        console.log(approvalGiven)
        if (approvalGiven === true) {
            setApprovalButtonVisible(false)
            
        }
        else {
            setApprovalButtonVisible(true)
        }

        // set the input to zero and calculate freshly
        if (sellPartVisible) {
            document.getElementById('assetAmountIn').value = '';
            calculateTradeResult()
        }
        else {
            document.getElementById('USDCAmountIn').value = '';
            calculateBuyResult();
        }
        setPriceDataVisible(false)        
    }

    const selectAssetInitial = async(asset, assetAddress, pair) =>{
        
        console.log(asset)
        console.log(assetAddress)
        console.log(pair)
        setSelectedAsset(asset)
        setSelectedAssetAddress(assetAddress)
        
        //get asset balance
        let tokenContract = await new web3_nm.eth.Contract(ERC20_ABI,assetAddress)
        let selectedAssetBalanceRaw = await tokenContract.methods.balanceOf(address).call()
        let _selectedAssetBalance = parseInt(selectedAssetBalanceRaw)/1e18
        //console.log(_selectedAssetBalance)
        await setSelectedAssetBalance(_selectedAssetBalance)
        
        checkAllowances(assetAddress)
        
        let MarketPair = new web3_nm.eth.Contract(MarketPair_ABI,pair)
        let balanceWEI = await MarketPair.methods.balanceOf(address).call()
        var balance = parseFloat(web3_nm.utils.fromWei(balanceWEI.toString(), 'ether'))
        let totalSupplyWEI = await MarketPair.methods.totalSupply().call();
        let reserves = await MarketPair.methods.getReserves().call();
        let token0 = await MarketPair.methods.token0().call();
        let token1 = await MarketPair.methods.token1().call();
        let kFactor = reserves[0] * reserves[1];

        setKFactor(kFactor)
        setReserves0(reserves[0])
        setReserves1(reserves[1])
        setToken0(token0)
        setToken1(token1)

        console.log(reserves)
        
        let _tokenPrice
        if (token0 === USDC_Address) {
            _tokenPrice = parseInt(reserves[0])/parseInt(reserves[1])
        }
        else{
            _tokenPrice = parseInt(reserves[1])/parseInt(reserves[0])
        }
        setTokenPrice(_tokenPrice)
        

        //check if the Approval button needs to be shown
        let approvalGiven = await checkApproval(assetAddress, MarketRouter_Address)
        console.log(approvalGiven)
        if (approvalGiven === true) {
            setApprovalButtonVisible(false)
        }
        else {
            setApprovalButtonVisible(true)

        }

        // set the input to zero and calculate freshly
        if (sellPartVisible) {
            document.getElementById('assetAmountIn').value = 0;
            calculateTradeResult()
        }
        else {
            try{document.getElementById('USDCAmountIn').value = 0}catch{console.log("error")};
            calculateBuyResult();
        }
        setPriceDataVisible(false)
    }

    




    const calculateBuyResult = async() =>{
        console.log(selectedAsset)
        if (selectedAsset === 'Select Asset'){
            document.getElementById('USDCAmountIn').value = ''
            document.getElementById('TokenPayoutAmount').value = ''
            return
        }
        
        checkAllowances(selectedAssetAddress)
        // Check if the input is greater zero
        let input = document.getElementById('USDCAmountIn').value
        console.log(input)
        if (parseFloat(input) === 0) {
            document.getElementById('TokenPayoutAmount').value = ''
            setBuyButtonVisible(false)
            setPriceDataVisible(false)
            return
        }
        const isPositiveNumber = /^((0|[1-9]\d*)(\.\d*)?|\.\d+)$/.test(input);

        console.log(isPositiveNumber)
        if (isPositiveNumber === false){
            if (document.getElementById('USDCAmountIn').value === '' || typeof(USDCAmountIn) === 'undefined' ){
                document.getElementById('TokenPayoutAmount').value = ''
                document.getElementById('USDCAmountIn').value = ''
                setPriceDataVisible(false)
                return
            }
            else{
                console.log("TADA")
                console.log(USDCAmountIn)
                document.getElementById('USDCAmountIn').value = USDCAmountIn/(10**USDDecimals)
            }
            return
        }
        var _USDCAmountIn = parseFloat(input)*(10**USDDecimals)
        
        // Check if there is enough balance
        console.log("USDbalance:", USDCBalance)
        console.log("Amount chosen: ", _USDCAmountIn)
        if (_USDCAmountIn > parseFloat(USDCBalance)*(10**USDDecimals)) {
            console.log("Not enough balance");
            setBuyButtonVisible(false)
            setWarningButtonVisible(true)
            setWarningButtonMessage("Balance too low")
        }
        else {
            setBuyButtonVisible(true)
            setWarningButtonVisible(false)
        }  

        console.log(_USDCAmountIn)
        if (_USDCAmountIn === 0){return}
        var _TokenPayoutAmount
        var _liquidityProviderFee = _USDCAmountIn * 0.003
        var _actualPrice
        
        if (token1 === USDC_Address) {
            let newUSDCPoolBalance = parseInt(reserves1) + parseInt(_USDCAmountIn)
            let newTokenReserves = Number(kFactor) / newUSDCPoolBalance
            _TokenPayoutAmount = (parseInt(reserves0) - newTokenReserves) * 0.997
            
            _actualPrice = _USDCAmountIn / _TokenPayoutAmount
            if (isNaN(_actualPrice)){
                document.getElementById('TokenPayoutAmount').value = ''
                document.getElementById('USDCAmountIn').value = ''
                return
            }
            document.getElementById('TokenPayoutAmount').value = (_TokenPayoutAmount/(1000000000000000000)).toFixed(6)
        }
        else if (token0 === USDC_Address){
            let newUSDCPoolBalance = parseInt(reserves0) + parseInt(_USDCAmountIn)
            console.log(parseInt(reserves0))
            console.log(reserves0)
            console.log(newUSDCPoolBalance)
            console.log(Number(kFactor))
            let newTokenReserves = Number(kFactor) / newUSDCPoolBalance
            console.log(reserves1)
            console.log(newTokenReserves)
            _TokenPayoutAmount = (parseInt(reserves1) - newTokenReserves) * 0.997
            console.log(TokenPayoutAmount)
            _actualPrice = _USDCAmountIn / _TokenPayoutAmount
            console.log(_actualPrice)
            if (isNaN(_actualPrice)){
                document.getElementById('TokenPayoutAmount').value = ''
                document.getElementById('USDCAmountIn').value = ''
                return
            }
            document.getElementById('TokenPayoutAmount').value = (_TokenPayoutAmount/(1000000000000000000)).toFixed(6)
        }
        setTokenPayoutAmount(_TokenPayoutAmount)
        let _slippage = slippage / 10000
        let _TokenPayoutAmountMin = _TokenPayoutAmount * (1-parseFloat(_slippage))
        console.log(_slippage)
        _TokenPayoutAmountMin = parseInt(_TokenPayoutAmountMin)
        console.log(_TokenPayoutAmountMin)
        setTokenPayoutAmountMin(_TokenPayoutAmountMin)

        //// ////
        setUSDCAmountIn(_USDCAmountIn)
        setLiquidityProviderFee(_liquidityProviderFee)
        setActualPrice(_actualPrice)
        //console.log(_actualPrice)
        console.log(parseFloat(tokenPrice))
        let priceImpactBuy = ((_actualPrice - parseFloat(tokenPrice))/parseFloat(tokenPrice))*100
        console.log("Price impact",priceImpactBuy)
        setPriceImpact(priceImpactBuy)
        await sleep(50)
        let _path = [USDC_Address,selectedAssetAddress]
        console.log(_path)
        let _deadline = Math.round(+new Date()/1000) + (60*10)
        setPath(_path)
        setDeadline(_deadline)  
        //await calculateBuy()   
        if (priceImpactBuy>0){setPriceDataVisible(true) } else {setPriceDataVisible(false) }  
    }

    const calculateBuyResultToken = async() =>{
        if (selectedAsset === 'Select Asset'){
            document.getElementById('USDCAmountIn').value = ''
            document.getElementById('TokenPayoutAmount').value = ''
            return
        }
        checkAllowances(selectedAssetAddress)
        
        let input = document.getElementById('TokenPayoutAmount').value
        console.log(input)

        if (parseFloat(input) === 0) {
            document.getElementById('USDCAmountIn').value = ''
            setBuyButtonVisible(false)
            setPriceDataVisible(false)
            return
        }
        const isPositiveNumber = /^((0|[1-9]\d*)(\.\d*)?|\.\d+)$/.test(input);

        console.log(isPositiveNumber)
        if (isPositiveNumber === false){
            if (document.getElementById('TokenPayoutAmount').value === '' || typeof(TokenPayoutAmount) === 'undefined' ){
                document.getElementById('USDCAmountIn').value = ''
                document.getElementById('TokenPayoutAmount').value = ''
                setTokenPayoutAmount()
                setPriceDataVisible(false)
                return
            }
            else{
                console.log("TADA")
                document.getElementById('TokenPayoutAmount').value = TokenPayoutAmount/1e18
            }
            return
        }
               
        
        // Check if the input is greater zero
        var _TokenPayoutAmount = parseFloat(input)*1e18
        //console.log(_TokenPayoutAmount)
        if (_TokenPayoutAmount < 0){
            _TokenPayoutAmount = TokenPayoutAmount
            document.getElementById('TokenPayoutAmount').value = _TokenPayoutAmount/-1e18
        }
        var _actualPrice
        var _USDCAmountIn

        if (token0 === USDC_Address) {
            let newTokenReserves = parseInt(reserves1) - _TokenPayoutAmount
            let newUSDCPoolBalance = Number(kFactor) / newTokenReserves
            _USDCAmountIn = (newUSDCPoolBalance - parseInt(reserves0))/0.997
            //console.log(_USDCAmountIn/(10**USDDecimals))
            _actualPrice = _USDCAmountIn / _TokenPayoutAmount
            document.getElementById('USDCAmountIn').value = (_USDCAmountIn/(10**USDDecimals)).toFixed(14)
        }
        else if (token1 === USDC_Address){
            let newTokenReserves = parseInt(reserves0) - _TokenPayoutAmount
            let newUSDCPoolBalance = Number(kFactor) / newTokenReserves
            _USDCAmountIn = ((newUSDCPoolBalance - parseInt(reserves1)))/0.997
            _actualPrice = _USDCAmountIn / _TokenPayoutAmount
            document.getElementById('USDCAmountIn').value = (_USDCAmountIn/(10**USDDecimals)).toFixed(14)
            
        }
        _USDCAmountIn = parseInt(_USDCAmountIn)
        // Check if there is enough balance
        console.log("USDbalance:", USDCBalance)
        console.log("Amount chosen: ", _USDCAmountIn)
        if (_USDCAmountIn > parseFloat(USDCBalance)*1000000000000000000) {
            console.log("Not enough balance");
            setBuyButtonVisible(false)
            setWarningButtonVisible(true)
            setWarningButtonMessage("Balance too low")
        }
        else {
            console.log("Debug")
            setBuyButtonVisible(true)
            setWarningButtonVisible(false)
        }  

        var _liquidityProviderFee = _USDCAmountIn * 0.003
        setTokenPayoutAmount(_TokenPayoutAmount)

        let _slippage = slippage / 10000
        let _TokenPayoutAmountMin = _TokenPayoutAmount * (1-parseFloat(_slippage))
        _TokenPayoutAmountMin = parseInt(roundDown(_TokenPayoutAmountMin,14).toFixed(14).replace(/\.?0+$/,""));
        console.log(_TokenPayoutAmountMin)

        setTokenPayoutAmountMin(_TokenPayoutAmountMin)
        setUSDCAmountIn(_USDCAmountIn)
        setLiquidityProviderFee(_liquidityProviderFee)
        console.log(_actualPrice)
        
        let _priceImpactBuy = ((_actualPrice - parseFloat(tokenPrice))/parseFloat(tokenPrice))*100
        console.log("Price impact",_priceImpactBuy)
        setPriceImpactBuy(_priceImpactBuy)
        let _path = [USDC_Address,selectedAssetAddress]
        console.log(_path)
        let _deadline = Math.round(+new Date()/1000) + (60*10)
        setPath(_path)
        setDeadline(_deadline)  
        await sleep(50)
        await calculateBuy()      
        if (_priceImpactBuy>0){setPriceDataVisible(true) } else {setPriceDataVisible(false)} 
    }  
    


    const calculateTradeResult = async() =>{
        if (selectedAsset === 'Select Asset'){
            document.getElementById('assetAmountIn').value = ''
            document.getElementById('USDCPayoutAmount').value = ''
            return
        }
        if (selectedAssetAddress === ''){return}
        checkAllowances(selectedAssetAddress)
        let input = document.getElementById('assetAmountIn').value
        
        const isPositiveNumber = /^((0|[1-9]\d*)(\.\d*)?|\.\d+)$/.test(input);


        console.log(isPositiveNumber)
        var _AssetAmountIn = parseFloat(document.getElementById('assetAmountIn').value)*1e18
        if (isPositiveNumber === false){
            console.log(document.getElementById('assetAmountIn').value)
            if (document.getElementById('assetAmountIn').value === ''){
                document.getElementById('USDCPayoutAmount').value = ''
                setPriceDataVisible(false)
                setBuyButtonVisible(false)
                return
            }
            if (typeof(AssetAmountIn) === 'undefined'){
                document.getElementById('USDCPayoutAmount').value = ''
                document.getElementById('assetAmountIn').value = ''
                return
            }
            else{
                console.log("here")
                console.log(AssetAmountIn)
                document.getElementById('assetAmountIn').value  = AssetAmountIn/1e18
                return
            }
        }       
        if (parseFloat(input) === 0) {
            document.getElementById('USDCPayoutAmount').value = ''
            setBuyButtonVisible(false)
            setPriceDataVisible(false)
            return
        }
        
        // Check if there is enough balance
        console.log("Tokenbalance:", selectedAssetBalance)
        console.log("Amount chosen: ", _AssetAmountIn)
        if (_AssetAmountIn > parseFloat(selectedAssetBalance)*1e18) {
            console.log("Not enough balance");
            setSellButtonVisible(false)
            setWarningButtonVisible(true)
            setWarningButtonMessage("Balance too low")
        }
        else {
            //console.log("Sell button should be visible")
            setSellButtonVisible(true)
            setWarningButtonVisible(false)
        }        

        
        var _USDCPayoutAmount
        var _liquidityProviderFee
        var _actualPrice
        if (token0 === USDC_Address) {
            
            let newTokenReserves = parseInt(reserves1) + _AssetAmountIn
            let newUSDCPoolBalance = Number(kFactor) / newTokenReserves
            _USDCPayoutAmount = (parseInt(reserves0) - newUSDCPoolBalance) * 0.997
            _liquidityProviderFee = (parseInt(reserves0) - newUSDCPoolBalance) * 0.003
            _actualPrice = _USDCPayoutAmount / _AssetAmountIn
            if ( isNaN(_actualPrice)){
                document.getElementById('USDCPayoutAmount').value = ''
                return
            }
            console.log(_actualPrice)
            document.getElementById('USDCPayoutAmount').value = (_USDCPayoutAmount/(10**(USDDecimals))).toFixed(6)
        }
        else {
            let newTokenReserves = parseInt(reserves0) + _AssetAmountIn
            let newUSDCPoolBalance = Number(kFactor) / newTokenReserves
            _USDCPayoutAmount = (parseInt(reserves1) - newUSDCPoolBalance) * 0.997
            _liquidityProviderFee = (parseInt(reserves1) - newUSDCPoolBalance) * 0.003
            _actualPrice = _USDCPayoutAmount / _AssetAmountIn
            if ( isNaN(_actualPrice)){
                document.getElementById('USDCPayoutAmount').value = ''
                return
            }
            console.log(_actualPrice)
            document.getElementById('USDCPayoutAmount').value = (_USDCPayoutAmount/(10**(USDDecimals))).toFixed(2)
        }
        setUSDCPayoutAmount(_USDCPayoutAmount)

        let _slippage = slippage / 10000
        let _USDCPayoutAmountMin = _USDCPayoutAmount * (1-parseFloat(_slippage))
        //console.log(_USDCPayoutAmountMin)
        var _amountOutMin = web3_nm.utils.toBigInt(parseInt(_USDCPayoutAmountMin))
        //console.log(_amountOutMin)
        
        setAmountOutMin(_amountOutMin)
        setActualPrice(_actualPrice)
        setUSDCPayoutAmountMin(_USDCPayoutAmountMin)
        setAssetAmountIn(_AssetAmountIn)
        setLiquidityProviderFee(_liquidityProviderFee)

        //console.log(_actualPrice)
        //console.log(_USDCPayoutAmountMin)
        let _priceImpact = ((_actualPrice - parseFloat(tokenPrice))/parseFloat(tokenPrice))*-100
        console.log("Price impact",_priceImpact)
        setPriceImpact(_priceImpact)
        await calculateSell()
        await sleep(500)
        
        if (_priceImpact>0){setPriceDataVisible(true)} else {setPriceDataVisible(false) }  
    }

    const calculateSellResultUSD = async() =>{
        if (selectedAsset === 'Select Asset'){
            document.getElementById('assetAmountIn').value = ''
            document.getElementById('USDCPayoutAmount').value = ''
            return
        }
        checkAllowances(selectedAssetAddress)
        let input = document.getElementById('USDCPayoutAmount').value
        console.log(input)
        
        const isPositiveNumber = /^((0|[1-9]\d*)(\.\d*)?|\.\d+)$/.test(input);
        //console.log(isPositiveNumber)

        if (isPositiveNumber === false){
            if (document.getElementById('USDCPayoutAmount').value === ''){
                document.getElementById('assetAmountIn').value = ''
                setBuyButtonVisible(false)
                setPriceDataVisible(false)
                setUSDCPayoutAmount()
                return
            }
            else if (typeof(USDCPayoutAmount) === 'undefined'){
                document.getElementById('USDCPayoutAmount').value = ''
                document.getElementById('assetAmountIn').value = ''
                return
            }
            else{
                console.log("DDDD")
                document.getElementById('USDCPayoutAmount').value = USDCPayoutAmount/(10**USDDecimals)
            }
            return
        }       
        if (parseFloat(input) === 0) {
            document.getElementById('assetAmountIn').value = ''
            setBuyButtonVisible(false)
            setPriceDataVisible(false)
            return
        }
        var _USDCPayoutAmount = parseFloat(input)*(10**(USDDecimals))
        
        // Check if the input is >0
        if (_USDCPayoutAmount < 0){
            document.getElementById('USDCPayoutAmount').value = USDCPayoutAmount/-(10**USDDecimals)
        }
        
        var _liquidityProviderFee
        var _actualPrice
        var _AssetAmountIn

        if (token0 === USDC_Address) {
            let newUSDCPoolBalance = parseInt(reserves0) - _USDCPayoutAmount 
            let newTokenReserves = Number(kFactor) / newUSDCPoolBalance
            _AssetAmountIn = -(Number(reserves1) - Number(newTokenReserves))/0.997
            document.getElementById('assetAmountIn').value = (_AssetAmountIn/(1000000000000000000)).toFixed(9)
            _liquidityProviderFee = (parseInt(reserves0) - newUSDCPoolBalance) * 0.003
            _actualPrice = _USDCPayoutAmount / _AssetAmountIn
            
        }
        else {
            let newUSDCPoolBalance = parseInt(reserves1) - _USDCPayoutAmount 
            let newTokenReserves = Number(kFactor) / newUSDCPoolBalance
            _AssetAmountIn = -(Number(reserves0) - Number(newTokenReserves))/0.997
            document.getElementById('assetAmountIn').value = (_AssetAmountIn/(1000000000000000000)).toFixed(9)
            _liquidityProviderFee = (parseInt(reserves1) - newUSDCPoolBalance) * 0.003
            _actualPrice = USDCPayoutAmount / _AssetAmountIn
        }

        // Check if there is enough balance
        console.log("USD balance:", USDCBalance)
        console.log("Amount choosen: ", _AssetAmountIn)
        if (_AssetAmountIn > parseFloat(selectedAssetBalance)*1000000000000000000) {
            console.log("Not enough balance");
            setSellButtonVisible(false)
            setWarningButtonVisible(true)
            setWarningButtonMessage("Balance too low")
        }
        else {
            //console.log("Buttons visible")
            setSellButtonVisible(true)
            setWarningButtonVisible(false)
        }
        setUSDCPayoutAmount(_USDCPayoutAmount)

        let _slippage = slippage / 10000
        let _USDCPayoutAmountMin = _USDCPayoutAmount * (1-parseFloat(_slippage))
        setUSDCPayoutAmountMin(_USDCPayoutAmountMin)
        console.log(_USDCPayoutAmountMin)
        var _amountOutMin = web3_nm.utils.toBigInt(parseInt(_USDCPayoutAmountMin))
        console.log(_amountOutMin)
        setAmountOutMin(_amountOutMin)

        setLiquidityProviderFee(_liquidityProviderFee)
        setActualPrice(_actualPrice)
        //console.log(_actualPrice)
        console.log(parseFloat(tokenPrice))
        let _priceImpact = ((_actualPrice - parseFloat(tokenPrice))/parseFloat(tokenPrice))*-100
        console.log("Price impact",_priceImpact)
        
        setPriceImpact(_priceImpact)
        await calculateSell()
        await sleep(500)
        if (_priceImpact>0){setPriceDataVisible(true) } else {setPriceDataVisible(false) }  
        
        
    }

    const calculateSell = async() =>{
        console.log("DEBUG")
        if (selectedAsset === 'Select Asset'){
            console.log("Debug")
            document.getElementById('assetAmountIn').value = ''
            return
        }
        var amountInRaw = document.getElementById('assetAmountIn').value
        console.log(amountInRaw)
        console.log(isNaN(amountInRaw))
        if (amountInRaw === ''){
            amountInRaw = String(0)
        }
        else if (isNaN(amountInRaw)){

            console.log("debug")
            amountInRaw = String(AssetAmountIn /1e18)
            console.log(amountInRaw)
            document.getElementById('assetAmountIn').value = amountInRaw
            
        }
        var _amountIn = ethers.utils.parseUnits(amountInRaw, 18);
        if ((_amountIn >= 0) === false){
            _amountIn = AssetAmountIn
            document.getElementById('assetAmountIn').value = _amountIn/1e18
            //console.log("debug")
            return
        }       
        
        console.log(_amountIn)
        // console.log(web3_nm.utils.toBigInt(amountIn))

        
        let _path = [selectedAssetAddress,USDC_Address]
        let _deadline = Math.round(+new Date()/1000) + (60*10)
        setAmountIn(_amountIn)
        setDeadline(_deadline)
        setPath(_path)
    }
    const onSuccessSell = async() =>{
        let selectedAssetOld = selectedAsset
        let selectedAssetAddressOld = selectedAssetAddress
        let selectedAssetBalanceOld = selectedAssetBalance
        let newBalance = parseFloat(selectedAssetBalanceOld) - parseFloat(AssetAmountIn/1e18);
        document.getElementById('assetAmountIn').value = 0;
        document.getElementById('USDCPayoutAmount').value = 0;
        document.getElementById('assetAmountIn').value = 0;
        setSelectedAssetBalance(newBalance);
        setPriceDataVisible(false);
        setSelectedAsset("Select Asset")
        setSelectedAssetBalance(0)

        await updateUserData();
        console.log("HONEY balance loaded")
        if (selectedAssetOld === 'TWIN'){
            await updateISSPrice()
        }
        await updateAssetBalanceWithAddress(selectedAssetAddressOld);
        await updatePriceWithAddress(selectedAssetAddressOld)
        await updatePortfolioValue();
    }

    const calculateBuy = async() =>{
        let _path = [USDC_Address,selectedAssetAddress]
        let _deadline = Math.round(+new Date()/1000) + (60*10)
        setPath(_path)
        setDeadline(_deadline)        
    }

    const onSuccessBuy = async() =>{
        let selectedAssetOld = selectedAsset
        let selectedAssetAddressOld = selectedAssetAddress
        let selectedAssetBalanceOld = selectedAssetBalance
        await setPriceDataVisible(false)
        await setSelectedAsset("Select Asset")
        await setSelectedAssetAddress('')

        document.getElementById('USDCAmountIn').value = 0;
        document.getElementById('TokenPayoutAmount').value = 0;
        
        let newBalance = parseFloat(TokenPayoutAmount/1e18) + parseFloat(selectedAssetBalanceOld);
        await setSelectedAssetBalance(newBalance)
        await loadUSDBalance();
        await updateAssetBalanceWithAddress(selectedAssetAddressOld);
        await updatePriceWithAddress(selectedAssetAddressOld)
        await updatePortfolioValue()
    }

    const checkApproval = async(tokenAddress, approvalAddress) =>{  
        console.log(tokenAddress)
        console.log(approvalAddress)
        let tokenContract = new web3_nm.eth.Contract(ERC20_ABI,tokenAddress)
        var amountRaw = 100000000000000000000000000000
        var amount = web3_nm.utils.toBigInt(amountRaw)
        
        let allowance = await tokenContract.methods.allowance(address, approvalAddress).call()

        if (parseInt(allowance) < parseInt(amount)){
            return(false)
        }
        else {return (true)}
    };


    const onSuccessApprove = async() =>{
        checkAllowances(selectedAssetAddress)
        
    }

    const showSell = async() =>{
        setStyle1("selectButtonUnselected")
        setStyle2("selectButtonSelected")
        setBuyPartVisible(false)
        setSellPartVisible(true)
        try{document.getElementById('assetAmountIn').value = 0;} catch{return}
        calculateTradeResult()
    }

    const showBuy = async() =>{

        setStyle1("selectButtonSelected")
        setStyle2("selectButtonUnselected")
        setBuyPartVisible(true)
        setSellPartVisible(false)
        try{document.getElementById('USDCAmountIn').value = 0;} catch{return}
        calculateBuyResult();

    }

    const setMaxSellBalanceToken = async() =>{
        //document.getElementById('assetAmountIn').value = selectedAssetBalance;
        document.getElementById('assetAmountIn').value = roundDown(selectedAssetBalance,14).toFixed(14).replace(/\.?0+$/,"");
        calculateTradeResult()
    }
    const setPercentOfAssetSell = async(_percentage) =>{
        //document.getElementById('assetAmountIn').value = selectedAssetBalance;
        document.getElementById('assetAmountIn').value = roundDown(selectedAssetBalance*_percentage/100,14).toFixed(14).replace(/\.?0+$/,"");
        calculateTradeResult()
    }

    const setMaxBuyBalanceUSD = async() =>{
        document.getElementById('USDCAmountIn').value = roundDown(USDCBalance,6).toFixed(6).replace(/\.?0+$/,"");
        calculateBuyResult()
    }

    const setPercentOfUSDC = async(_percentage) =>{
        document.getElementById('USDCAmountIn').value = roundDown(USDCBalance*_percentage/100,6).toFixed(6).replace(/\.?0+$/,"");
        calculateBuyResult()
    }
    const setPercentOfAssetBuy = async(_percentage) =>{
        document.getElementById('TokenPayoutAmount').value = roundDown(selectedAssetBalance*_percentage/100,14).toFixed(14).replace(/\.?0+$/,"");
        calculateBuyResultToken()
    }


    
    return ( 
        
            <div className="mainContainer">

                <Modal show={chooseAssetModalOpen} onHide={closeChooseAssetModal}>
                    <Modal.Header className="" closeButton>
                        <Modal.Title>Select a token</Modal.Title>   
                    </Modal.Header>
                    
                    <Modal.Body className="" style={{
                        maxHeight: 'calc(100vh - 210px)',
                        overflowY: 'auto'
                        }} 
                    >
                        <div className="row p-3 pr-3 my-auto">
                            <input className="col w-100 searchField" id="search" placeholder="Search" onChange={() =>filterAssets()}></input>
                            
                        </div>
                        <div className="list-group">
                            {listAssets()}
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={settingsModalOpen} onHide={closeSettingsModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Chose your preferences</Modal.Title>   
                    </Modal.Header>
                    <Modal.Body className="" style={{
                        maxHeight: 'calc(100vh - 210px)',
                        overflowY: 'auto'
                        }} 
                    >
                        <div className="settingsRow">
                            <div className="">Slippage tolerance</div>
                            <div className="settingsInputArea">
                                <input className="" type="decimals" id="slippage" onChange={saveSettingsSlippage} placeholder={slippage/100}></input>
                                <PercentOutlinedIcon  fontSize='inherit'/>
                            </div>
                            
                        </div>
                        {wrongSlippageInputMessage
                            ?
                            <div className="row pl-3 pt-3 w-100 text-danger ">Please input a valid slippage percentage</div>
                            :
                            ''
                        }
                        
                        {highSlippageInputMessage
                            ?
                            <div className="row pl-3 pt-3 w-100 text-danger ">Warning. Your transaction may be frontrun</div>
                            :
                            ''
                        }
                        
                        <div>&nbsp;</div>

                        <div className="settingsRow">
                            <div className="">Max transaction time</div>
                            <div className="settingsInputArea">
                                <input className="" type="decimals" id="trxTime" onChange={saveSettingsTrxTime} placeholder={trxTime}></input>
                                <AccessTimeOutlinedIcon  fontSize='inherit'/>
                                
                            </div>
                            
                        </div>
                        {wrongTrxTimeInputMessage
                            ?
                            <div className="row pl-3 pt-3 w-100 text-danger ">Please input a valid transaction time</div>
                            :
                            ''
                        }                           
                    </Modal.Body>
                </Modal>

                    <div className="middleBoxSmall">
                        <div className="mainBox">
                            <div className="selectButtonRow">
                                <div  className={style1} onClick={showBuy} role="button">
                                    <b>Buy</b>
                                </div>
                                <div  className={style2} onClick={showSell} role="button">
                                    <b>Sell</b>
                                </div>
                            </div>
                            <div className="selectSettingsRow">
                                <Gear className="h4" role="button" onClick={openSettingsModal}/>
                                
                            </div>
                            
                                
                                
                                {sellPartVisible 
                                ?
                                <div>
                                    <div className="subBox">
                                        <b>Sell</b>
                                        <div className="selectAmountRow">
                                            {selectedAsset !== "Select Asset" ? <div className="percentBox" onClick={() =>setPercentOfAssetSell(25)} role="button" >25%</div>:''}
                                            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                            {selectedAsset !== "Select Asset" ? <div className="percentBox" onClick={() =>setPercentOfAssetSell(50)} role="button" >50%</div>:''}
                                            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                            {selectedAsset !== "Select Asset" ? <div className="percentBox" onClick={() =>setPercentOfAssetSell(75)} role="button" >75%</div>:''}
                                            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                            {selectedAsset !== "Select Asset" ? <div className="percentBox" onClick={() =>setPercentOfAssetSell(100)} role="button" >100%</div>:''}
                                            
                                        </div>

                                        
                                        <div className="tradingBoxRow">
                                            <input id="assetAmountIn" onChange={() =>calculateTradeResult()} className="inputCustom" type="text" lang="en" placeholder="0.00"/>
                                            <div className="selectAssetButton" onClick={openChooseAssetModalSell}>
                                                <div className='assetBoxIcon'>
                                                    <img className="" src={assetIcon} alt="honey" width="24px"/>
                                                </div>
                                                    {selectedAsset} <KeyboardArrowDownOutlinedIcon />  
                                            </div>
                                        </div>
                                        <div onClick={setMaxSellBalanceToken} role="button" className="col align-self-end text-lg-right textBalance">
                                            {selectedAsset !== "Select Asset" ? (
                                                <span>
                                                    Balance: {selectedAssetBalance > 0.0001 ? (
                                                        <span>{outputNumber(selectedAssetBalance, 4)}</span>
                                                ) : (
                                                    <span>&lt; 0.0001</span>
                                                )} (Max)
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="d-flex align-items-center justify-content-center h-100 py-2">
                                            <KeyboardDoubleArrowDownOutlinedIcon /> 
                                        </div>
                                        
                                    </div>
                                    
                                    <div className="subBox px-4 py-4 ">
                                        <b>Buy</b>
                                        

                                        
                                        <div className="tradingBoxRow">
                                            
                                            <input id="USDCPayoutAmount" onChange={() =>calculateSellResultUSD()} className="inputCustom" type="text" lang="en" placeholder="0.00"/>
                                            <div className='stablecoinAssetBox'>
                                                <div className='assetBoxIcon'>
                                                    <img className="" src={honeyIcon} alt="honey" width="24px"/>
                                                </div>
                                                {stableCoinName}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col textBalance">Balance: {outputNumber(USDCBalance,2)}</div>
                                        </div>
                                    </div>
                                    {priceDataVisible 
                                        ?
                                        <div className="container  py-4 pr-4 ">
                                            <div className="row">
                                                <div className="col align-self-start">Price:</div>
                                                <div className="col align-self-end text-lg-right">{stableCoinName} per {selectedAsset}: {parseFloat(actualPrice).toFixed(3)}</div>
                                            </div>
                                        </div>

                                        : ''
                                    }
                                    {selectedAssetBalance > allowanceToken && priceDataVisible
                                    ?

                                    <div className="py-3">
                                        <TransactionButton
                                            contractAddress={selectedAssetAddress} 
                                            abi = {ERC20_ABI}
                                            functionName= 'approve'
                                            args =  {[MarketRouter_Address,web3_nm.utils.toBigInt(2**255)]} // Example, adjust as needed
                                            text="Approve"
                                            onSuccess={onSuccessApprove}
                                        />
                                    </div>
                                    :
                                        ''
                                    }
                                    
                                    {selectedAssetBalance < allowanceToken && priceDataVisible && warningButtonVisible === false & typeof(amountIn) !== 'undefined' & typeof(amountOutMin) !== 'undefined'
                                        ?
                                        <div className="py-3">
                                            <TransactionButton
                                                abi={MarketRouter_ABI} // Adjust this to where you're storing ABI
                                                contractAddress={MarketRouter_Address} 
                                                functionName="swapExactTokensForTokens" 
                                                args = {[parseInt(amountIn),parseInt(amountOutMin),path,address, deadline]}
                                                text="Trade"
                                                onSuccess = {onSuccessSell}
                                            />
                                        </div>
                                        :
                                        ''
                                    }
                                    {warningButtonVisible
                                        ?
                                        <div className="py-3">
                                            <div deactivated className="issuaaButtonDeactivated">{warningButtonMessage}</div>
                                        </div>
                                        :
                                        ''
                                    }


                                    {priceDataVisible 
                                    ?
                                    <div className="subBox px-4 py-4 ">
                                        <div>Minimum to receive: {outputNumber(parseFloat(USDCPayoutAmountMin/(10**USDDecimals)),2)}</div>
                                        <div>Price impact: {outputNumber(priceImpact,2)}%</div>
                                        <div>Liquidity Provide Fee: {outputNumber(parseFloat(liquidityProviderFee)/(10**USDDecimals),2)} {stableCoinName}</div>
                                    </div>
                                    :
                                    ''
                                    }
                                </div>
                                :
                                ''
                            }


                                {buyPartVisible
                                ?
                                <div>
                                    <div className="subBox">
                                        <b>Sell</b>
                                        <div className="selectAmountRow">

                                            <div className="percentBox" onClick={() =>setPercentOfUSDC(25)} role="button" >25%</div>
                                            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                            <div className="percentBox" onClick={() =>setPercentOfUSDC(50)} role="button" >50%</div>
                                            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                            <div className="percentBox" onClick={() =>setPercentOfUSDC(75)} role="button" >75%</div>
                                            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                            <div className="percentBox"  onClick={() =>setPercentOfUSDC(100)} role="button" >100%</div>
                                            
                                        </div>

                                        
                                        <div className="tradingBoxRow">
                                            <input className="inputCustom" id="USDCAmountIn" onChange={() =>calculateBuyResult()}  type="text" lang="en" placeholder="0.00"/>
                                            
                                            <div className='stablecoinAssetBox'>
                                                <div className='assetBoxIcon'>
                                                    <img className="" src={honeyIcon} alt="honey" width="24px"/>
                                                </div>
                                                {stableCoinName}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col textBalance">Balance: {outputNumber(USDCBalance,2)}</div>
                                        </div>
                                        
                                    </div>

                                    <div>
                                        <div className="d-flex align-items-center justify-content-center h-100 py-2">
                                            <KeyboardDoubleArrowDownOutlinedIcon />    
                                        </div>
                                        
                                    </div>    

                                    <div className="subBox">
                                        <b>Buy</b>
                                        

                                    

                                        <div className="tradingBoxRow">
                                            <input id="TokenPayoutAmount" onChange={() =>calculateBuyResultToken()} className="inputCustom" type="text" lang="en" placeholder="0.00"/>
                                            
                                            <div role="button" className="selectAssetButton" onClick={openChooseAssetModal}>
                                                <div className='assetBoxIcon'>
                                                    <img className="" src={assetIcon} alt="honey" width="24px"/>
                                                </div>
                                                <div>{selectedAsset} <KeyboardArrowDownOutlinedIcon />   </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                                {selectedAsset !== "Select Asset" ? (
                                                    <div className="col textBalance">
                                                        Balance: {selectedAssetBalance > 0.001 ? (
                                                                <span>{outputNumber(selectedAssetBalance, 4)}</span>
                                                            ) : (
                                                                <span>&lt; 0.001</span>
                                                            )} 
                                                        
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                    </div>
                                    
                                    
                                    
                                    {priceDataVisible 
                                        ?
                                        <div className="container  py-4 pr-4">
                                            <div className="row">
                                                <div className="col align-self-start">Price:</div>
                                                <div className="col align-self-end text-lg-right">{stableCoinName} per {selectedAsset}: {outputNumber(parseFloat(actualPrice),3)}</div>
                                            </div>
                                        </div>

                                        : ''
                                    }
                                    {USDCAmountIn > allowanceUSDC && priceDataVisible
                                    ?
                                    <div className="py-3">
                                        <TransactionButton
                                            contractAddress={USDC_Address} 
                                            abi = {ERC20_ABI}
                                            functionName= 'approve'
                                            args =  {[MarketRouter_Address,web3_nm.utils.toBigInt(2**255)]} 
                                            text="Approve"
                                            onSuccess={onSuccessApprove}
                                        />
                                    </div>
                                    :
                                        ''
                                    }
                                    
                                    {USDCAmountIn < allowanceUSDC && warningButtonVisible === false && priceDataVisible
                                        ?
                                        <div className="py-3">
                                            <div className="py-3">
                                            <TransactionButton
                                                abi={MarketRouter_ABI} // Adjust this to where you're storing ABI
                                                contractAddress={MarketRouter_Address} 
                                                functionName="swapExactTokensForTokens" 
                                                args = {[USDCAmountIn,TokenPayoutAmountMin,path,address, deadline]}
                                                text="Trade"
                                                onSuccess = {onSuccessBuy}
                                            />
                                        </div>
                                        </div>
                                        :
                                        ''
                                    }
                                    {warningButtonVisible
                                        ?
                                        <div className="py-3">
                                            <div className="btn issuaaButtonDeactivated w-100">{warningButtonMessage}</div>
                                        </div>
                                        :
                                        ''
                                    }

                                    {priceDataVisible 
                                    ?
                                    <div className="subBox px-4 py-4 ">
                                        <div>Minimum to receive: {outputNumber(parseFloat(TokenPayoutAmountMin/1000000000000000000),6)}</div>
                                        <div>Price impact: {parseFloat(priceImpactBuy).toFixed(2)}%</div>
                                        <div>Liquidity Provider Trading Fee: {outputNumber(parseFloat(liquidityProviderFee/(10**USDDecimals)),2)} {stableCoinName}</div>
                                    </div>
                                    :
                                    ''
                                    }
                                </div>
                                :
                                ''
                            }    

                            </div>
                        
                        
                            

                        
                    </div>
            </div>
                    
                    
        
    );
}

 
export default Market;