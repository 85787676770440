import Web3 from 'web3';
import axios from 'axios';
import {ERC20_ABI,VotingEscrow_ABI,assetFactory_ABI,MarketPair_ABI,MarketFactory_ABI,MarketRouter_ABI} from '../config/config';



var web3_nm

var _USDC_Address = '0x0E4aaF1351de4c0264C5c7056Ef3777b41BD8e03'
const GovernanceTokenAddress = '0xcE7DB5cbA54fa37597c6ECcC91c46e3FB7812390'
const DistributionTokenAddress = '0xCC725D0ED6ca86b5d3a6C17DA2cfD4a9D856B300'

const AssetFactoryAddress = '0x1452FD3480957Dc38d980Fc95Fb35881e396e9F3'
const MarketFactoryAddress = '0xeB51D566a85Eb39D751b7B995345d587e6F1295A'
const MarketRouterAddress = '0x1daF8A8fE63C71E84c85D30d308B9214a7f43EF8'
//const MarketFactoryAddress = '0xE0aCFa888AdDe20712daBEaC224cd1AE1fa2Ac06'
//const MarketRouterAddress = '0xBC55715C0c1cD4B64a1C9457d201Dd1AC36eEA28'
const VotingEscrowAddress = '0x9eE799ac82BC99EF4d552c6870d59Baf7a37959b'
const APIBase = "https://api.twinfinance.io"
var USDDecimals = 18
const assetNumber = 1; // HARDCODED - needs to be adjusted after new assets are launched
const GovernanceTokenSymbol = "TWIN"
      
async function getWeb3Instance(chain="bartio"){
  let _web3
  if (chain === 'bartio'){
    try {
      _web3 = new Web3(new Web3.providers.HttpProvider('https://bartio.rpc.berachain.com/'));
    } catch (error) {
      console.error('Error initializing Web3 with bArtio:', error);
      try {
        _web3 = new Web3(new Web3.providers.HttpProvider('https://bartio.rpc.berachain.com/'));
      } catch (error) {
        console.error('Error initializing Web3 with Ankr:', error);
        return;
      }
    }
  }
  return _web3
}
async function getGovernanceTokenData(_address,chain='bartio'){
  var _governanceTokenData = {}
  let response
  try{response = await axios.get(APIBase+'/governanceTokenData');}
  catch{response = {status:404}}
  console.log(response)
  if (response.status === 200){
    console.log("User data successfully fetched.")
    _governanceTokenData = response.data    
  }
  else {
    let web3_nm = await getWeb3Instance(chain)
  
    let GovernanceTokenContract = new web3_nm.eth.Contract(ERC20_ABI,GovernanceTokenAddress)
    let VotingEscrowContract = new web3_nm.eth.Contract(VotingEscrow_ABI,VotingEscrowAddress)
    
    let _ISSSupplyWei = await GovernanceTokenContract.methods.totalSupply().call()
    let _ISSSupply = parseFloat(web3_nm.utils.fromWei(_ISSSupplyWei.toString(), 'ether'))
    let _ISSBalanceMultisigWei = await GovernanceTokenContract.methods.balanceOf('0x0E4aaF1351de4c0264C5c7056Ef3777b41BD8e03').call()
    let _ISSBalanceDeployerWei = await GovernanceTokenContract.methods.balanceOf('0x7Dbc67df4d4ea21420B1BaA077028d2c1CCa7399').call()
    let _ISSBalanceLockedWei = await GovernanceTokenContract.methods.balanceOf('0x6D849F0D197D1535dbC853a3dA05e5702A7E12Ce').call()
    let _ISSBalanceDAOWei = await GovernanceTokenContract.methods.balanceOf('0xD98B0b96aCc058695D1d2fd2D41C1e739Cf4F7C1').call()
    let _ISSBalanceNotCirculatingWei = _ISSBalanceDeployerWei + _ISSBalanceMultisigWei + _ISSBalanceLockedWei + _ISSBalanceDAOWei
    let _ISSSupplyCurrentWei  = _ISSSupplyWei - _ISSBalanceNotCirculatingWei
    let _ISSSupplyCurrent = parseFloat(web3_nm.utils.fromWei(_ISSSupplyCurrentWei.toString(), 'ether'))

    let _totalVeISSSupplyWei = await VotingEscrowContract.methods.totalSupply().call();
    let _totalVeISSSupply = parseFloat(web3_nm.utils.fromWei(_totalVeISSSupplyWei.toString(), 'ether'))
    _governanceTokenData['GovernanceTokenCurrentSupply'] = _ISSSupplyCurrent
    _governanceTokenData['GovernanceTokenTotalSupply'] = _ISSSupply
    _governanceTokenData['TotalVeSupply'] = _totalVeISSSupply
  
  }
return (_governanceTokenData)
}

async function getUserData(_address,chain='bartio'){
  var _uData = {}
  let response
  try{response = await axios.get(APIBase+'/userData?address='+_address);}
  catch{response = {status:404}}
  console.log(response)
  
  if (response.status === 200){
    console.log("User data successfully fetched.")
    _uData = response.data    
  }
  else {
    let web3_nm = await getWeb3Instance(chain)
  
    let USDC_nm = new web3_nm.eth.Contract(ERC20_ABI,_USDC_Address)
    let GovernanceTokenContract = new web3_nm.eth.Contract(ERC20_ABI,GovernanceTokenAddress)
    let DistributionTokenContract = new web3_nm.eth.Contract(ERC20_ABI,DistributionTokenAddress)
    let VotingEscrowContract = new web3_nm.eth.Contract(VotingEscrow_ABI,VotingEscrowAddress)
    _uData['address'] = _address
    console.log("Henlo. API seems to be down. Going directly to blockchain")
    var _USDCBalanceWEI = await USDC_nm.methods.balanceOf(_address).call()
    var USDDecimals = await USDC_nm.methods.decimals().call()
    let _USDCBalance = parseInt(_USDCBalanceWEI) / 10**parseInt(USDDecimals)
    _uData['StableCoinBalance'] = _USDCBalance

    // Load the Government Token and IDT balance as well as the staked amaount
    let _DistributionTokenBalanceWei = await DistributionTokenContract.methods.balanceOf(_address).call()
    let _DistributionTokenBalance = parseFloat(web3_nm.utils.fromWei(_DistributionTokenBalanceWei.toString(), 'ether'))
    let _GovernanceTokenBalanceWei = await GovernanceTokenContract.methods.balanceOf(_address).call()
    let _GovernanceTokenBalance = parseFloat(web3_nm.utils.fromWei(_GovernanceTokenBalanceWei.toString(), 'ether'))
    let _userData = await VotingEscrowContract.methods.userData(_address).call()
    let _GovernanceTokenStakeBalanceWei = _userData['_lockedBalance']['amount']    
    let _GovernanceTokenStakeBalance = parseFloat(web3_nm.utils.fromWei(_GovernanceTokenStakeBalanceWei.toString(), 'ether'))
    let _veISSBalanceWei = _userData['_balanceVeISS']
    let _veISSBalance = parseFloat(web3_nm.utils.fromWei(_veISSBalanceWei.toString(), 'ether'))
    let _lockDate = _userData['_lockedBalance']['end']
    _uData['DistributionTokenBalance'] = _DistributionTokenBalance
    _uData['GovernanceTokenBalance'] = _GovernanceTokenBalance
    _uData['lockTime'] = _lockDate
    _uData['lockedBalance'] = _GovernanceTokenStakeBalance
    _uData['veBalance'] = _veISSBalance
  }
  
  return (_uData)
}

async function getPrice(tokenAddress, chain='bartio'){
  try{
    console.log(tokenAddress)
    let web3_nm = await getWeb3Instance(chain)
    console.log(web3_nm)
    let MarketFactory = await new web3_nm.eth.Contract(MarketFactory_ABI,MarketFactoryAddress)
    let pair = await MarketFactory.methods.getPair(tokenAddress,_USDC_Address).call() 
    console.log(pair)
    let MarketPair = await new web3_nm.eth.Contract(MarketPair_ABI,pair)
    let token0 = await MarketPair.methods.token0().call();
    let reserves = await MarketPair.methods.getReserves().call();

    let USDReserves;
    let price;
    let reservesToken
    let tvl

    if (token0 === _USDC_Address) {
      USDReserves = parseInt(reserves[0]) 
      reservesToken = parseInt(reserves[1])
      price = USDReserves * 10**(18-USDDecimals)/reservesToken
    }
    else{
      USDReserves = parseInt(reserves[1]) 
      reservesToken = parseInt(reserves[0])
      price = USDReserves * 10**(18-USDDecimals)/reservesToken
    }
    tvl = 2 * USDReserves / 10**(USDDecimals)
    if (isNaN(price)){
      price = 0.00
      tvl = 0
    }
    return([price,tvl,reservesToken,USDReserves])
  }
  catch{
    return([0,0,0,0])
  }
}

async function getTVL(tokenAddress, chain='bartio'){
  let web3_nm = await getWeb3Instance(chain)
  let MarketPair = await new web3_nm.eth.Contract(MarketPair_ABI,tokenAddress)
  let token0 = await MarketPair.methods.token0().call();
  let reserves = await MarketPair.methods.getReserves().call();
  let USDReserves;
  let tvl

  if (token0 === _USDC_Address) {
    USDReserves = parseInt(reserves[0]) 
    tvl = USDReserves * 2 / 10**USDDecimals
  }
  else{
    USDReserves = parseInt(reserves[1]) 
    tvl = USDReserves * 2 / 10**USDDecimals
  }
  return(tvl)
}


async function getAssetData(chain = 'bartio') {
  var assetData = {};
  var _assetDetails = {};
  var _pools = [];
  let response;

  try {
    response = await axios.get(APIBase+'/assetData?chain=' + chain);
  } catch (error) {
    response = { status: 404 };
    console.error('Error fetching asset data from API:', error);
  }
  console.log(response);

  if (response.status === 200) {
    console.log('Asset data successfully fetched from API.');
    assetData = response.data;
    return ( [assetData[0], assetData[1]] )
  } else {
    console.log('Fetching asset data from blockchain.');

    let web3_nm = await getWeb3Instance(chain)
  

    try {
      let AssetFactoryContract = new web3_nm.eth.Contract(assetFactory_ABI, AssetFactoryAddress);
      let MarketFactoryContract = new web3_nm.eth.Contract(MarketFactory_ABI, MarketFactoryAddress);

      let _assets = [];
      let totalLockedValue = 0
      let newAsset;

      // Add the ISS Token to the pools
      try {
        let pair = await MarketFactoryContract.methods.getPair(GovernanceTokenAddress, _USDC_Address).call();
        let MarketPair = new web3_nm.eth.Contract(MarketPair_ABI, pair);
        let totalSupply = await MarketPair.methods.totalSupply().call();
        console.log(totalSupply)
        let priceandtvl = await getPrice(GovernanceTokenAddress);
        let price = priceandtvl[0] 
        let tvl = priceandtvl[1] 
        let reservesToken = priceandtvl[2]
        let reservesUSD = priceandtvl[3] 
        
        _pools.push([
          'TWIN',
          pair,
          tvl,
          'TWIN Protocol Token',
          0,
          parseInt(totalSupply),
          0,
          reservesUSD,
          reservesToken,
          'n.a.',
          price,
          GovernanceTokenAddress
        ]);
        //console.log('TWIN token added to pools:', _pools[_pools.length - 1]);
      } catch (error) {
        console.error('Error adding TWIN token to pools:', error);
      }

      for (let i = 0; i < assetNumber; i++) {
        try {
          newAsset = await AssetFactoryContract.methods.assets(i).call();
          //console.log('New asset fetched:', newAsset);

          _assets.push(newAsset);
          _assetDetails[newAsset] = await AssetFactoryContract.methods.getAsset(newAsset).call();
          //console.log('Asset details:', _assetDetails[newAsset]);

          let tokenAddress = _assetDetails[newAsset]['Token1'];
          
          try {
            let pair = await MarketFactoryContract.methods.getPair(tokenAddress, _USDC_Address).call();
            let MarketPair = new web3_nm.eth.Contract(MarketPair_ABI, pair);
            let totalSupply = await MarketPair.methods.totalSupply().call();
            let priceandtvl = await getPrice(tokenAddress);
            let price = priceandtvl[0] 
            let tvl = priceandtvl[1] 
            let reservesToken = priceandtvl[2]
            let reservesUSD = priceandtvl[3] 

            
            _pools.push([
              newAsset,
              pair,
              tvl,
              _assetDetails[newAsset][2],
              0,
              parseInt(totalSupply),
              0,
              reservesUSD,
              reservesToken,
              _assetDetails[newAsset]['upperLimit'],
              price,
              tokenAddress
            ]);
            _assetDetails[newAsset]['priceLong'] = price;
            console.log('Token1 added to pools:', _pools[_pools.length - 1]);
          } catch (error) {
            console.error('Error fetching pair for Token1:', tokenAddress, error);
          }

          tokenAddress = _assetDetails[newAsset]['Token2'];

          try {
            let pair = await MarketFactoryContract.methods.getPair(tokenAddress, _USDC_Address).call();
            let MarketPair = new web3_nm.eth.Contract(MarketPair_ABI, pair);
            let totalSupply = await MarketPair.methods.totalSupply().call();
            let priceandtvl = await getPrice(tokenAddress);
            let price = priceandtvl[0] 
            let tvl = priceandtvl[1]
            let reservesToken = priceandtvl[2]
            let reservesUSD = priceandtvl[3] 
            _pools.push([
              'i'+newAsset,
              pair,
              tvl,
              'short ' + _assetDetails[newAsset][2],
              0,
              parseInt(totalSupply),
              0,
              reservesUSD,
              reservesToken,
              _assetDetails[newAsset]['upperLimit'],
              price,
              tokenAddress
            ]);
            _assetDetails[newAsset]['priceShort'] = price;
            console.log('Token2 added to pools:', _pools[_pools.length - 1]);
          } catch (error) {
            console.error('Error fetching pair for Token2:', tokenAddress, error);
          }

        } catch (error) {
          console.error('Error fetching asset:', i, error);
        }
      }
    } catch (error) {
      console.error('Error in fetching asset data from blockchain:', error);
    }
  }

  console.log('Final pools:', _pools);
  console.log('Final asset details:', _assetDetails);

  return ( [_pools, _assetDetails] )
}

async function getAssetBalances(_address, chain = 'bartio') {
  
  console.log(_address)
  var response;
  var assetBalances = []
  try {
    response = await axios.get(APIBase+'/assetBalances?address='+_address+'&chain=' + chain);
  } 
  catch (error) {
    response = { status: 404 };
    console.error('Error fetching asset data from API:', error);
  }
  //console.log(response);

  if (response.status === 200) {
    console.log('Asset data successfully fetched from API.');
    assetBalances = response.data;
  } 
  else {
    console.log('Fetching asset balances from blockchain.');
    let web3_nm = await getWeb3Instance(chain)
  
    let AssetFactoryContract = new web3_nm.eth.Contract(assetFactory_ABI, AssetFactoryAddress);
    for (let i = 0; i < assetNumber; i++) {
      //console.log(i)
      let newAsset = await AssetFactoryContract.methods.assets(i).call();
      let _assetDetails = await AssetFactoryContract.methods.getAsset(newAsset).call();
      //console.log(_assetDetails)
      let addressTokenLong = _assetDetails['Token1']
      let addressTokenShort = _assetDetails['Token2']
      //console.log(addressTokenLong)
      //console.log(addressTokenShort)
      let TokenContractLong = new web3_nm.eth.Contract(ERC20_ABI, addressTokenLong);   
      let TokenContractShort = new web3_nm.eth.Contract(ERC20_ABI, addressTokenShort);
      //console.log(_address)
      let tokenBalanceLong = parseInt(await TokenContractLong.methods.balanceOf(_address).call()) / 1e18 
      //console.log(tokenBalanceLong) 
      let tokenBalanceShort = parseInt(await TokenContractShort.methods.balanceOf(_address).call()) / 1e18
      //console.log(tokenBalanceShort) 
      assetBalances.push([newAsset,tokenBalanceLong,tokenBalanceShort])    
    }
    //console.log(assetBalances) 
  }
  return (assetBalances)
  
  
  
}

async function getLPBalances(_address, chain = 'bartio') {
  let web3_nm = await getWeb3Instance(chain)
  console.log(_address)
  var response;
  var lpBalances = {}
  try {
    response = await axios.get(APIBase+'/LPBalances?address='+_address+'&chain=' + chain);
  } 
  catch (error) {
    response = { status: 404 };
    console.error('Error fetching asset data from API:', error);
  }
  //console.log(response);

  if (response.status === 200) {
    console.log('Asset data successfully fetched from API.');
    lpBalances = response.data;
  } 
  else {
    console.log('Fetching lp balances from blockchain.');
    let AssetFactoryContract = new web3_nm.eth.Contract(assetFactory_ABI, AssetFactoryAddress);
    let MarketFactoryContract = new web3_nm.eth.Contract(MarketFactory_ABI, MarketFactoryAddress);

    // ADD ISS
    let pair = await MarketFactoryContract.methods.getPair(GovernanceTokenAddress,_USDC_Address).call() 
    let MarketPair = await new web3_nm.eth.Contract(MarketPair_ABI,pair)  
    let balance = parseInt(await MarketPair.methods.balanceOf(_address).call()) / 1e18
    lpBalances['TWIN'] = balance 
    for (let i = 0; i < assetNumber; i++) {
      let newAsset = await AssetFactoryContract.methods.assets(i).call();
      let _assetDetails = await AssetFactoryContract.methods.getAsset(newAsset).call();
      //console.log(_assetDetails)
      let addressTokenLong = _assetDetails['Token1']
      let pair = await MarketFactoryContract.methods.getPair(addressTokenLong,_USDC_Address).call()
      let MarketPair = await new web3_nm.eth.Contract(MarketPair_ABI,pair)
      let balanceLong = parseInt(await MarketPair.methods.balanceOf(_address).call()) / 1e18
      lpBalances[newAsset] = balanceLong 
       
      let addressTokenShort = _assetDetails['Token2']
      
      pair = await MarketFactoryContract.methods.getPair(addressTokenShort,_USDC_Address).call()
      MarketPair = await new web3_nm.eth.Contract(MarketPair_ABI,pair)
      let balanceShort = parseInt(await MarketPair.methods.balanceOf(_address).call()) / 1e18
      lpBalances['i'+newAsset] = balanceShort
      
       
    }
    
  }
  return (lpBalances)
  
  
  
}
async function getSingleAssetBalance(_address, _assetAddress, chain = 'bartio') {
  console.log("Debug")
  var response;
  var assetBalance = 0
  try {
    response = await axios.get(APIBase+'/assetBalance?address='+_address+'&chain=' + chain+'&assetAddress=' + _assetAddress);
  } 
  catch (error) {
    response = { status: 404 };
    console.error('Error fetching asset data from API:', error);
  }
  console.log(response);

  if (response.status === 200) {
    console.log('Asset data successfully fetched from API.');
    assetBalance = response.data;
  } 
  else {
    console.log('Fetching asset balance from blockchain.');
    let web3_nm = await getWeb3Instance(chain)
    let TokenContract = new web3_nm.eth.Contract(ERC20_ABI, _assetAddress);
    assetBalance = parseInt(await TokenContract.methods.balanceOf(_address).call()) / 1e18 
  }
  return (assetBalance)
}

async function getAssetBalancesFromTicker(_address, _ticker, chain = 'bartio') {
  
  var response;
  var assetBalances = []
  try {
    response = await axios.get(APIBase+'/assetBalancesFromTicker?address='+_address+'&chain=' + chain+'&ticker=' + _ticker);
  } 
  catch (error) {
    response = { status: 404 };
    console.error('Error fetching asset data from API:', error);
  }
  console.log(response);

  if (response.status === 200) {
    console.log('Asset data successfully fetched from API.');
    assetBalances = response.data;
  } 
  else {
    console.log('Fetching asset balances from blockchain.');
    let web3_nm = await getWeb3Instance(chain)
    if (_ticker === GovernanceTokenSymbol){
      let TokenContractLong = new web3_nm.eth.Contract(ERC20_ABI, GovernanceTokenAddress);
      let tokenBalanceLong = parseInt(await TokenContractLong.methods.balanceOf(_address).call()) / 1e18
      return ([tokenBalanceLong,"nm"])   
    }
    let AssetFactoryContract = new web3_nm.eth.Contract(assetFactory_ABI, AssetFactoryAddress);
    let _assetDetails = await AssetFactoryContract.methods.getAsset(_ticker).call();
    let addressTokenLong = _assetDetails['Token1']
    let addressTokenShort = _assetDetails['Token2']
    let TokenContractLong = new web3_nm.eth.Contract(ERC20_ABI, addressTokenLong);   
    let TokenContractShort = new web3_nm.eth.Contract(ERC20_ABI, addressTokenShort);
    let tokenBalanceLong = parseInt(await TokenContractLong.methods.balanceOf(_address).call()) / 1e18 
    let tokenBalanceShort = parseInt(await TokenContractShort.methods.balanceOf(_address).call()) / 1e18
    assetBalances = [tokenBalanceLong,tokenBalanceShort]  
  }
  return (assetBalances)
  
  
  
}
async function getLPBalancesFromTicker(_address, _ticker, chain = 'bartio') {
  
  var response;
  var lpBalances = []
  try {
    response = await axios.get(APIBase+'/assetBalancesFromTicker?address='+_address+'&chain=' + chain+'&ticker=' + _ticker);
  } 
  catch (error) {
    response = { status: 404 };
    console.error('Error fetching asset data from API:', error);
  }
  console.log(response);

  if (response.status === 200) {
    console.log('Asset data successfully fetched from API.');
    lpBalances = response.data;
  } 
  else {
    console.log('Fetching asset balances from blockchain.');
    let web3_nm = await getWeb3Instance(chain)
    if (_ticker === GovernanceTokenSymbol){
      let TokenContractLong = new web3_nm.eth.Contract(ERC20_ABI, GovernanceTokenAddress);
      let tokenBalanceLong = parseInt(await TokenContractLong.methods.balanceOf(_address).call()) / 1e18
      return ([tokenBalanceLong,"nm"])   
    }
    let AssetFactoryContract = new web3_nm.eth.Contract(assetFactory_ABI, AssetFactoryAddress);
    let _assetDetails = await AssetFactoryContract.methods.getAsset(_ticker).call();
    let addressTokenLong = _assetDetails['Token1']
    let addressTokenShort = _assetDetails['Token2']
    let MarketFactory = await new web3_nm.eth.Contract(MarketFactory_ABI,MarketFactoryAddress)
    let pair = await MarketFactory.methods.getPair(addressTokenLong,_USDC_Address).call()
    let MarketPair = await new web3_nm.eth.Contract(MarketPair_ABI,pair)
    let lpBalanceLong = await parseInt(MarketPair.methods.balanceOf(_address).call())/ 1e18

    pair = await MarketFactory.methods.getPair(addressTokenShort,_USDC_Address).call()
    MarketPair = await new web3_nm.eth.Contract(MarketPair_ABI,pair)
    let lpBalanceShort = await parseInt(MarketPair.methods.balanceOf(_address).call())/ 1e18

    
    lpBalances = [lpBalanceLong,lpBalanceShort]  
  }
  return (lpBalances)
  
  
  
}



export {getUserData,getGovernanceTokenData,getAssetData,getPrice,getAssetBalances,getLPBalances,getSingleAssetBalance,getAssetBalancesFromTicker,getLPBalancesFromTicker};
